import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import CmsModules from '@sportson/core-web/libs/wordpress/content/CmsModules';
import React from 'react';
import { shallowEqual } from 'react-redux';

const NotFound = () => {
    const heroContent = useAppSelector(({ page }) => page?.data?.heroContent, shallowEqual);
    const pageContent = useAppSelector(({ page }) => page?.data?.pageContent, shallowEqual);

    return (
        <>
            {heroContent && <CmsModules isHero data={heroContent} />}
            {pageContent && <CmsModules data={pageContent} />}
        </>
    );
};

export default NotFound;
